<template>
  <div id="changeForm">
    <el-dialog
      :title="changeFormFormTitle"
      width="1200px"
      :visible.sync="changeFormDialogVisible"
      :close-on-click-modal="false"
      @close="changeFormDialogClose"
    >
      <el-form
        ref="changeFormFormRef"
        :model="changeFormForm"
        :rules="changeFormFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="原项目名称" prop="projectName">
              <el-input v-model="changeFormForm.projectName" placeholder="请输入原项目名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原项目编号" prop="projectNo">
              <el-input v-model="changeFormForm.projectNo" placeholder="请输入原项目编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请类型" prop="applicationType">
              <el-radio-group v-model="changeFormForm.applicationType">
                <el-radio :label="1">
                  新增
                </el-radio>
                <el-radio :label="2">
                  删减
                </el-radio>
                <el-radio :label="3">
                  变更
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请内容" prop="applicationContent">
              <el-input
                v-model="changeFormForm.applicationContent"
                placeholder="请输入申请内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请理由" prop="applicationReason">
              <el-input
                v-model="changeFormForm.applicationReason"
                placeholder="请输入申请理由"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签字" prop="contentSignature">
              <el-input v-model="changeFormForm.contentSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="contentSignatureTime">
              <el-date-picker v-model="changeFormForm.contentSignatureTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签字" prop="reasonSignature">
              <el-input v-model="changeFormForm.reasonSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="reasonSignatureTime">
              <el-date-picker v-model="changeFormForm.reasonSignatureTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="研究内容" prop="researchContentType">
              <el-radio-group v-model="changeFormForm.researchContentType">
                <el-radio :label="1">
                  无变动
                </el-radio>
                <el-radio :label="2">
                  有变动
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目费用" prop="costType">
              <el-radio-group v-model="changeFormForm.costType">
                <el-radio :label="1">
                  无变动
                </el-radio>
                <el-radio :label="2">
                  有变动
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目进度" prop="progressType">
              <el-radio-group v-model="changeFormForm.progressType">
                <el-radio :label="1">
                  无变动
                </el-radio>
                <el-radio :label="2">
                  有变动
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="变动内容" prop="researchContentChange">
              <el-input
                v-model="changeFormForm.researchContentChange"
                placeholder="请输入变动内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="变动内容" prop="costContentChange">
              <el-input
                v-model="changeFormForm.costContentChange"
                placeholder="请输入变动内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="变动内容" prop="progressContentChange">
              <el-input
                v-model="changeFormForm.progressContentChange"
                placeholder="请输入变动内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目领导委员意见" prop="leaderOpinion">
              <el-input
                v-model="changeFormForm.leaderOpinion"
                placeholder="请输入项目领导委员意见"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="leaderSignature">
              <el-input v-model="changeFormForm.leaderSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="leaderSignatureTime">
              <el-date-picker v-model="changeFormForm.leaderSignatureTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="changeFormDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="changeFormFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="原项目名称">
        <el-input v-model="searchForm.projectName" placeholder="请输入原项目名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="changeFormPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="projectName" label="原项目名称" />
      <el-table-column prop="projectNo" label="原项目编号" />
      <el-table-column label="申请类型">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationType === 1">新增</span>
          <span v-if="scope.row.applicationType === 2">删减</span>
          <span v-if="scope.row.applicationType === 3">变更</span>
        </template>
      </el-table-column>
      <el-table-column prop="applicationContent" label="申请内容" />
      <el-table-column prop="applicationReason" label="申请理由" />
      <el-table-column prop="researchContentChange" label="研究内容" />
      <el-table-column prop="costContentChange" label="项目费用" />
      <el-table-column prop="progressContentChange" label="项目进度" />
      <el-table-column prop="leaderOpinion" label="项目领导委员意见" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="changeFormPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addChangeForm, deleteChangeForm, updateChangeForm, selectChangeFormInfo, selectChangeFormList } from '@/api/technology/changeForm'

export default {
  data () {
    return {
      changeFormDialogVisible: false,
      changeFormFormTitle: '',
      changeFormForm: {
        id: '',
        projectName: '',
        projectNo: '',
        applicationType: '',
        applicationContent: '',
        contentSignature: '',
        contentSignatureTime: '',
        applicationReason: '',
        reasonSignature: '',
        reasonSignatureTime: '',
        researchContentType: '',
        researchContentChange: '',
        costType: '',
        costContentChange: '',
        progressType: '',
        progressContentChange: '',
        leaderOpinion: '',
        leaderSignature: '',
        leaderSignatureTime: ''
      },
      changeFormFormRules: {
        projectName: [{ required: true, message: '原项目名称不能为空', trigger: ['blur', 'change']}]
      },
      changeFormPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        projectName: ''
      }
    }
  },
  created () {
    selectChangeFormList(this.searchForm).then(res => {
      this.changeFormPage = res
    })
  },
  methods: {
    changeFormDialogClose () {
      this.$refs.changeFormFormRef.resetFields()
    },
    changeFormFormSubmit () {
      if (this.changeFormFormTitle === '设计更改申请单详情') {
        this.changeFormDialogVisible = false
        return
      }
      this.$refs.changeFormFormRef.validate(async valid => {
        if (valid) {
          if (this.changeFormFormTitle === '新增设计更改申请单') {
            await addChangeForm(this.changeFormForm)
          } else if (this.changeFormFormTitle === '修改设计更改申请单') {
            await updateChangeForm(this.changeFormForm)
          }
          this.changeFormPage = await selectChangeFormList(this.searchForm)
          this.changeFormDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.changeFormFormTitle = '新增设计更改申请单'
      this.changeFormDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteChangeForm(row.id)
        if (this.changeFormPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.changeFormPage = await selectChangeFormList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.changeFormFormTitle = '修改设计更改申请单'
      this.changeFormDialogVisible = true
      this.selectChangeFormInfoById(row.id)
    },
    handleInfo (index, row) {
      this.changeFormFormTitle = '设计更改申请单详情'
      this.changeFormDialogVisible = true
      this.selectChangeFormInfoById(row.id)
    },
    selectChangeFormInfoById (id) {
      selectChangeFormInfo(id).then(res => {
        this.changeFormForm.id = res.id
        this.changeFormForm.projectName = res.projectName
        this.changeFormForm.projectNo = res.projectNo
        this.changeFormForm.applicationType = res.applicationType
        this.changeFormForm.applicationContent = res.applicationContent
        this.changeFormForm.contentSignature = res.contentSignature
        this.changeFormForm.contentSignatureTime = res.contentSignatureTime
        this.changeFormForm.applicationReason = res.applicationReason
        this.changeFormForm.reasonSignature = res.reasonSignature
        this.changeFormForm.reasonSignatureTime = res.reasonSignatureTime
        this.changeFormForm.researchContentType = res.researchContentType
        this.changeFormForm.researchContentChange = res.researchContentChange
        this.changeFormForm.costType = res.costType
        this.changeFormForm.costContentChange = res.costContentChange
        this.changeFormForm.progressType = res.progressType
        this.changeFormForm.progressContentChange = res.progressContentChange
        this.changeFormForm.leaderOpinion = res.leaderOpinion
        this.changeFormForm.leaderSignature = res.leaderSignature
        this.changeFormForm.leaderSignatureTime = res.leaderSignatureTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectChangeFormList(this.searchForm).then(res => {
        this.changeFormPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectChangeFormList(this.searchForm).then(res => {
        this.changeFormPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectChangeFormList(this.searchForm).then(res => {
        this.changeFormPage = res
      })
    }
  }
}
</script>

<style>
</style>
