import axios from '@/common/axios'
import qs from 'qs'

export function addChangeForm (data) {
  return axios({
    method: 'post',
    url: '/technology/change/add',
    data: qs.stringify(data)
  })
}

export function deleteChangeForm (id) {
  return axios({
    method: 'delete',
    url: '/technology/change/delete/' + id
  })
}

export function updateChangeForm (data) {
  return axios({
    method: 'put',
    url: '/technology/change/update',
    data: qs.stringify(data)
  })
}

export function selectChangeFormInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/change/info/' + id
  })
}

export function selectChangeFormList (query) {
  return axios({
    method: 'get',
    url: '/technology/change/list',
    params: query
  })
}
